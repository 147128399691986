import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Description of Polis open data`}</h3>
    <p>{`Each data set is a model of the opinions of a population at a given time, containing anonymous human expressions, in a political context, created through a process run by a facilitator, on open source software maintained by a nonprofit.`}</p>
    <h3>{`All Polis data is licensed CC By 4.0`}</h3>
    <p>{`All Polis data is licensed under a Creative Commons Attribution 4.0 International license: `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`https://creativecommons.org/licenses/by/4.0/`}</a></p>
    <h3>{`How to attribute Polis open data`}</h3>
    <pre><code parentName="pre" {...{}}>{`--------------- BEGIN STATEMENT ---------------

Data was gathered using the Polis software (compdemocracy.org/polis) and is sub-licensed under CC BY 4.0 with Attribution to The Computational Democracy Project. The data and more information about how the data was collected can be found at the following link(s):

<links to individual case study URLs>

--------------- END STATEMENT ---------------
`}</code></pre>
    <p>{`If you are using data from multiple conversations, you may use multiple links underneath a single attribution.`}</p>
    <h3>{`Individual case study URLs for attribution and citation`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2014-seattle-15-per-hour"
        }}>{`https://compdemocracy.org/case-studies/2014-seattle-15-per-hour`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2018-kentucky"
        }}>{`https://compdemocracy.org/case-studies/2018-kentucky`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2022-Austria-Klimarat"
        }}>{`https://compdemocracy.org/case-studies/2022-Austria-Klimarat`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2017-brexit-consensus"
        }}>{`https://compdemocracy.org/case-studies/2017-brexit-consensus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2016-canadian-electoral-reform"
        }}>{`https://compdemocracy.org/case-studies/2016-canadian-electoral-reform`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2015-football-concussions"
        }}>{`https://compdemocracy.org/case-studies/2015-football-concussions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2022-london-youth-policing"
        }}>{`https://compdemocracy.org/case-studies/2022-london-youth-policing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2018-march-on"
        }}>{`https://compdemocracy.org/case-studies/2018-march-on`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2017-scoop-hivemind-affordable-housing"
        }}>{`https://compdemocracy.org/case-studies/2017-scoop-hivemind-affordable-housing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2019-scoop-hivemind-biodiversity"
        }}>{`https://compdemocracy.org/case-studies/2019-scoop-hivemind-biodiversity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2017-scoop-hivemind-freshwater"
        }}>{`https://compdemocracy.org/case-studies/2017-scoop-hivemind-freshwater`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2018-scoop-hivemind-taxes"
        }}>{`https://compdemocracy.org/case-studies/2018-scoop-hivemind-taxes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2017-scoop-hivemind-ubi"
        }}>{`https://compdemocracy.org/case-studies/2017-scoop-hivemind-ubi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2021-san-juan-island"
        }}>{`https://compdemocracy.org/case-studies/2021-san-juan-island`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/case-studies/2015-vtaiwan-uberx"
        }}>{`https://compdemocracy.org/case-studies/2015-vtaiwan-uberx`}</a></li>
    </ul>
    <h3>{`For further information on best practices for Attribution of CC 4.0 licensed content`}</h3>
    <p>{`Please see: `}<a parentName="p" {...{
        "href": "https://wiki.creativecommons.org/wiki/Best_practices_for_attribution#Title.2C_Author.2C_Source.2C_License"
      }}>{`https://wiki.creativecommons.org/wiki/Best_practices_for_attribution#Title.2C_Author.2C_Source.2C_License`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      